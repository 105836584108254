/* eslint-disable no-loop-func */
import React, { Component } from "react";
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Card,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  ListItem,
  ListItemSecondaryAction,
  DialogActions,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  Add,
  BarChart,
  Clear,
  Close,
  KeyboardArrowRight,
  Logout,
  Receipt,
  Save,
} from "@mui/icons-material";
import "../App.css";
import axios from "../axios";
import { getAuthHeaders } from "../helpers";
import TotalChart from "../components/chart";
import VolumeChart from "../components/volume_chart";
import TotalStoreChart from "../components/total_chart";
import { MobileDatePicker } from "@mui/x-date-pickers/";
import moment from "moment";

class HomePage extends Component {
  shouldNotUpdate = false;

  departments = {
    call: {
      name: "Calls Made",
      count: 0,
    },
    credit_application: {
      name: "Credit Applications",
      count: 0,
      requiresSource: true,
    },
    contract: {
      name: "Contracts Signed",
      count: 0,
    },
    vehicle_delivered: {
      name: "Vehicles Delivered",
      count: 0,
      requiresSource: true,
    },
  };

  tabs = [
    { key: "service", name: "Service" },
    { key: "ql", name: "Quick Lane" },
  ];

  stores = {
    Airdrie: { bshop: 1, ql: 1 },
    Olds: { bshop: 0, ql: 1 },
    Innisfail: { bshop: 0, ql: 0 },
    "Red Deer": { bshop: 1, ql: 1 },
    Canmore: { bshop: 0, ql: 1 },
    "North Van": { bshop: 1, ql: 0 },
    Richmond: { bshop: 0, ql: 0 },
    "Kamloops Ford": { bshop: 1, ql: 0 },
    "Dearborn Ford": { bshop: 0, ql: 0 },
    Kia: { bshop: 0, ql: 0 },
    Mazda: { bshop: 0, ql: 0 },
    "Santa Monica": { bshop: 0, ql: 0 },
    "Evergreen Ford": { bshop: 0, ql: 0 },
    "Evergreen Chev": { bshop: 0, ql: 0 },
    "Clark Nissan": { bshop: 0, ql: 0 },
    "Clark Hyundai": { bshop: 0, ql: 0 },
  };

  state = {
    chartDialog: false,
    sourceDialog: false,
    addSourceDialog: false,
    savingSource: true,
    chartDataLoading: true,
    tickerDataLoading: true,
    startDate: moment().subtract(15, "day"),
    endDate: moment(),
    store: "Kia",
    chartType: "total",
    department: "call",
    tab: 0,
    dialogType: "chart",
    volumePerHour: null,
    storeTotalData: null,
    sources: [],
    sourceSelection: null,
    newSourceName: "",
    chartData: null,
    data: null,
    sourcesData: null,
    total: 0,
    user: "",
  };
  departmentChartCounter = 0;
  volumeChartCounter = 0;
  storeTotalChartCounter = 0;

  getUserFromSession = () => {
    try {
      return JSON.parse(sessionStorage.getItem("user"));
    } catch (error) {
      return {};
    }
  };

  user = this.getUserFromSession();

  handleChangeDepartment = (department) => {
    this.setState({ department }, () => this.getChartData(true));
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    this.props.history.go("/");
  };

  handleDateChange = (newDate, type = "startDate") => {
    this.setState({ [type]: newDate }, () => {
      this.getChartData(true);
      this.getVolumePerHourData(true);
    });
  };

  handleStoreChange = (e) => {
    this.setState({ store: e.target.value }, () => {
      localStorage.setItem("store", e.target.value);
      this.getTickerData(true);
      this.getChartData(true);
      this.getVolumePerHourData();
    });
  };
  handleUserChange = (e) => {
    this.setState(
      { user: e.target.value == "All" ? "" : e.target.value },
      () => {
        this.getChartData(true);
        this.getVolumePerHourData();
      }
    );
  };

  roundTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    // date.setMinutes(date.getMinutes() - (date.getMinutes() % 30));
    if (date.getMinutes() < 15) {
      date.setMinutes(0);
    } else if (date.getMinutes() < 45) {
      date.setMinutes(30);
    } else {
      date.setMinutes(0);
      date.setHours(date.getHours() + 1);
    }
    date.setDate(1);
    date.setMonth(1);
    date.setYear(2000);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  };

  getChartData = async (refreshOnly = false) => {
    if (this.shouldNotUpdate) return;
    try {
      if (refreshOnly) this.setState({ refreshOnly: true });
      else this.setState({ chartDataLoading: true });

      //in case another call was made during the previous data was being received
      let body = {
        store: this.state.store,
        startDate: this.state.startDate.format("YYYY-MM-DD"),
        type: this.state.department,
        endDate: this.state.endDate.format("YYYY-MM-DD"),
        allUsers: !!this.state.user ? null : true,
        username: this.state.user,
      };

      const result = await axios.post(`/v1/subprime/`, body, getAuthHeaders());
      var items = {};
      var sourcesData = {};
      var labels = [];
      var total = 0;
      result.data.forEach((row) => {
        if (!items[row.type]) {
          items[row.type] = 1;
        } else {
          items[row.type]++;
          total += items[row.type];
        }

        if (row.source !== "") {
          if (!sourcesData[row.source]) {
            sourcesData[row.source] = {};
          }
          if (!sourcesData[row.source][row.type]) {
            sourcesData[row.source][row.type] = 1;
            labels.push(row.type);
          } else sourcesData[row.source][row.type]++;
        }

        // if (this.state.department !== row.extra) items[this.state.department]++;
      });
      this.departmentChartCounter++;
      if (Object.keys(sourcesData).length === 0) sourcesData = null;
      this.setState({
        chartDataLoading: false,
        refreshOnly: false,
        chartData: items,
        sourcesData,
        labels,
        total,
      });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ chartDataLoading: false, refreshOnly: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  getTickerData = async (refreshOnly = false) => {
    if (this.shouldNotUpdate) return;
    if (!refreshOnly) this.setState({ tickerDataLoading: true });
    try {
      //in case another call was made during the previous data was being received
      let body = {
        store: this.state.store,
        startDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      };
      const result = await axios.post(`/v1/subprime/`, body, getAuthHeaders());
      var data = JSON.parse(JSON.stringify(this.departments));

      result.data.forEach((row) => {
        data[row.type].count++;
      });
      this.setState({
        tickerDataLoading: false,
        data,
        refreshOnly: false,
      });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ chartDataLoading: false, refreshOnly: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  getVolumePerHourData = async (refreshOnly = false) => {
    if (refreshOnly) this.setState({ refreshOnly: true });
    try {
      //in case another call was made during the previous data was being received
      let body = {
        store: this.state.store,
        startDate: this.state.startDate.format("YYYY-MM-DD"),
        endDate: this.state.endDate.format("YYYY-MM-DD"),
        allUsers: !!this.state.user ? null : true,
        username: this.state.user,
      };
      console.log("body");
      console.log(body);
      const result = await axios.post(`/v1/subprime/`, body, getAuthHeaders());
      console.log("volume per hour result.data for ", this.state.user);
      console.log(result.data);
      const volumePer30Minutes = {};
      var storeTotalData = JSON.parse(JSON.stringify(this.departments));
      var storeTotalUserBasedData = {};
      result.data.forEach((row) => {
        let roundedTimestamp = this.roundTimestamp(row.timestamp);
        var time = moment(roundedTimestamp).utc().format("HH:mm");
        if (!volumePer30Minutes[time]) {
          volumePer30Minutes[time] = {};
        }
        if (!volumePer30Minutes[time][row.type]) {
          volumePer30Minutes[time][row.type] = 0;
        }
        volumePer30Minutes[time][row.type]++;

        //store user based data
        if (!storeTotalUserBasedData[row.username])
          storeTotalUserBasedData[row.username] = {};

        if (!storeTotalUserBasedData[row.username][row.type])
          storeTotalUserBasedData[row.username][row.type] = 1;
        else storeTotalUserBasedData[row.username][row.type]++;

        //store user based data end

        //for storeTotal chart and table data
        storeTotalData[row.type].count++;
      });

      console.log("storeTotalUserBasedData");
      console.log(storeTotalUserBasedData);

      let timeArray = Object.entries(volumePer30Minutes);

      timeArray.sort(function (a, b) {
        let aTime = new Date("1970-01-01 " + a[0]);
        let bTime = new Date("1970-01-01 " + b[0]);
        return aTime - bTime;
      });

      let sortedvolumePer30Minutes = Object.fromEntries(timeArray);

      // console.log('volumePer30Minutes')
      // console.log(JSON.stringify(volumePer30Minutes))
      // console.log('sortedvolumePer30Minutes')
      // console.log(JSON.stringify(sortedvolumePer30Minutes))
      // console.log(Object.keys(sortedvolumePer30Minutes))
      this.volumeChartCounter++;
      this.setState({
        refreshOnly: false,
        volumePer30Minutes: sortedvolumePer30Minutes,
        storeTotalData,
        storeTotalUserBasedData,
      });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ refreshOnly: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  getSources = async () => {
    try {
      this.setState({ sourcesLoading: true });
      const result = await axios.get(
        `/v1/subprime/contract_source`,
        getAuthHeaders()
      );
      this.setState({ sources: result.data, savingSource: false });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ sourcesLoading: false, savingSource: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };
  getStoreUsers = async () => {
    try {
      this.setState({ usersLoading: true });
      console.log(`/v1/subprime/${this.state.store.toLowerCase()}/users`);
      const result = await axios.get(
        `/v1/subprime/${this.state.store.toLowerCase()}/users`,
        getAuthHeaders()
      );
      console.log("user result");
      console.log(result.data);
      var users = [];
      if (result.data.length > 0)
        users = result.data.map((user) => user.username);
      this.setState({ users, usersLoading: false });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ usersLoading: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  deleteSource = async (name, ID) => {
    try {
      let allowDelete = window.confirm(`Do you want to delete ${name} ?`);
      if (!allowDelete) return;
      this.setState({ deletingSourceID: ID });
      var sources = this.state.sources.filter((source) => source.ID !== ID);

      const result = await axios.delete(
        `/v1/subprime/contract_source/${ID}`,
        getAuthHeaders()
      );
      this.setState({ sources });
      this.setState({ deletingSourceID: null });
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      this.setState({ sourcesLoading: false, savingSource: false });
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  componentDidMount() {
    this.getSources();
    let store = localStorage.getItem("store");
    if (!store) store = "Airdrie";
    //forcing Kia store only for now
    store = "Kia";

    this.setState({ store }, () => {
      this.getTickerData();
      this.getChartData();
      this.getVolumePerHourData();
      this.getStoreUsers();
    });
    this.interval = setInterval(() => this.getTickerData(true), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleTickerCountUpdate = async ({ kind = "+", type, source = "" }) => {
    try {
      this.shouldNotUpdate = true;
      this.setState({ sourceSelection: null, refreshOnly: true }, () => {});
      var body = {
        kind,
        type,
        source,
        store: this.state.store,
      };
      const result = await axios.put(`/v1/subprime/`, body, getAuthHeaders());
      this.shouldNotUpdate = false;
      this.getTickerData(true);
    } catch (error) {
      this.shouldNotUpdate = false;
    }
  };

  addSource = async () => {
    try {
      const { newSourceName } = this.state;
      if (newSourceName.trim() === "")
        return alert("Source name cannot be empty");
      this.setState({ savingSource: true });
      var body = {
        department: this.tabs[this.state.tab].key,
        source: newSourceName,
        store: this.state.store,
      };
      const result = await axios.post(
        `/v1/subprime/contract_source`,
        body,
        getAuthHeaders()
      );
      this.setState({ addSourceDialog: false, newSourceName: "" });
      this.getSources();
    } catch (error) {
      this.setState({ savingSource: false });
    }
  };

  sourceSelectionHandler = ({ kind = "+", type }) => {
    this.setState({ sourceSelection: { kind, type } });
  };

  render() {
    return (
      <div>
        <Grid container justifyContent={"space-between"}>
          <h4>Hi {this.user.displayName}</h4>
          <div>
            <Button onClick={() => this.logout()} endIcon={<Logout />}>
              Logout
            </Button>
          </div>
        </Grid>

        {this.state.tickerDataLoading ? (
          <Grid container justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid
              container
              justifyContent={"center"}
              spacing={4}
              alignItems={"center"}
            >
              <Grid item>
                {/* <Select
                  label="Store"
                  style={{ minWidth: 150 }}
                  name="store"
                  value={this.state.store}
                  onChange={this.handleStoreChange}
                >
                  {Object.keys(this.stores).map((store) => (
                    <MenuItem value={store}>{store}</MenuItem>
                  ))}
                </Select> */}
              </Grid>
            </Grid>
            <Grid
              style={{ marginBottom: 16, marginTop: 8 }}
              container
              justifyContent={"center"}
              spacing={2}
              alignItems={"center"}
            >
              <Grid item>
                <Button
                  startIcon={<BarChart />}
                  variant="contained"
                  onClick={() => this.setState({ chartDialog: true })}
                >
                  VIEW CHART
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<Receipt />}
                  variant="contained"
                  onClick={() => this.setState({ sourceDialog: true })}
                >
                  Manage Sources
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ textAlign: "center", marginTop: 32 }}
              justifyContent="center"
              alignItems={"center"}
            >
              <Grid item xs={12} md={4}>
                <Card>
                  <table style={{ width: "100%", borderSpacing: 0 }}>
                    <tbody>
                      {Object.keys(this.state.data).map((department, index) => {
                        return (
                          <tr
                            style={{
                              background: index % 2 === 0 ? "#f2f2f2" : "#fff",
                              lineHeight: 0,
                            }}
                          >
                            <td
                              style={{
                                paddingLeft: 8,
                                textAlign: "left",
                                // fontSize: 1,
                                lineHeight: 0,
                                width: 300,
                              }}
                            >
                              <h3>{this.state.data[department].name}</h3>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  this.state.data[department].requiresSource
                                    ? this.sourceSelectionHandler({
                                        kind: "-",
                                        type: department,
                                      })
                                    : this.handleTickerCountUpdate({
                                        kind: "-",
                                        type: department,
                                      })
                                }
                                variant="contained"
                                style={{
                                  fontSize: 30,
                                  height: 30,
                                  background: "#3e95f0",
                                }}
                              >
                                -
                              </Button>
                            </td>
                            <td
                              style={{
                                paddingLeft: 8,
                                paddingRight: 8,
                                textAlign: "center",
                                fontSize: 30,
                              }}
                            >
                              {this.state.data[department].count ?? 0}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  this.state.data[department].requiresSource
                                    ? this.sourceSelectionHandler({
                                        kind: "+",
                                        type: department,
                                      })
                                    : this.handleTickerCountUpdate({
                                        kind: "+",
                                        type: department,
                                      })
                                }
                                variant="contained"
                                style={{
                                  fontSize: 30,
                                  height: 30,
                                  background: "#3e95f0",
                                }}
                              >
                                +
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </Grid>
            </Grid>
            {
              <Dialog
                fullScreen
                open={this.state.chartDialog}
                onClose={() => this.setState({ chartDialog: false })}
              >
                <AppBar sx={{ position: "relative" }}>
                  <Toolbar
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Select
                      style={{
                        minWidth: 150,
                        margin: 2,
                        height: 36,
                        background: "white",
                      }}
                      name="chartType"
                      id="chartType"
                      value={this.state.chartType}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="total">Total & Source</MenuItem>
                      {/* <MenuItem value="volume">Volume Per Hour</MenuItem> */}
                      <MenuItem value="store">User Total</MenuItem>
                    </Select>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => this.setState({ chartDialog: false })}
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <Grid
                    style={{ marginBottom: 16 }}
                    container
                    justifyContent={"center"}
                    spacing={2}
                  >
                    <Grid item>
                      <MobileDatePicker
                        //minDate from helpers.js
                        label="Start Date"
                        allowSameDateSelection
                        disableCloseOnSelect={false}
                        showToolbar={false}
                        showTodayButton
                        inputFormat="yyyy-MM-DD"
                        name="date"
                        disableFuture
                        views={["year", "month", "day"]}
                        value={this.state.startDate}
                        onChange={(date) =>
                          this.handleDateChange(date, "startDate")
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                    <Grid item>
                      <MobileDatePicker
                        label="End Date"
                        //minDate from helpers.js
                        allowSameDateSelection
                        disableCloseOnSelect={false}
                        showToolbar={false}
                        showTodayButton
                        inputFormat="yyyy-MM-DD"
                        name="date"
                        disableFuture
                        views={["year", "month", "day"]}
                        value={this.state.endDate}
                        onChange={(date) =>
                          this.handleDateChange(date, "endDate")
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ marginBottom: 16 }}
                    container
                    justifyContent={"center"}
                    spacing={2}
                  >
                    {/* <Grid item>
                      <Select
                        style={{ minWidth: 150 }}
                        name="store"
                        value={this.state.store}
                        onChange={this.handleStoreChange}
                      >
                        {Object.keys(this.stores).map((store) => (
                          <MenuItem value={store}>{store}</MenuItem>
                        ))}
                      </Select>
                    </Grid> */}
                    {this.state.chartType !== "total" && (
                      <Grid item>
                        <Select
                          style={{ minWidth: 150 }}
                          name="user"
                          value={
                            this.state.user == "" ? "All" : this.state.user
                          }
                          onChange={this.handleUserChange}
                        >
                          <MenuItem value={"All"}>All Users</MenuItem>
                          {this.state.users?.map((user) => (
                            <MenuItem value={user}>{user}</MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                    {/* {this.state.chartType === "total" && (
                      <Grid item>
                        <Select
                          style={{ minWidth: 150 }}
                          name="department"
                          id="department"
                          value={this.state.department}
                          onChange={this.handleChangeDepartment}
                        >
                          {Object.keys(this.departments)
                            .filter(
                              (department) =>
                                (department !== "ql" &&
                                  department !== "bshop") ||
                                this.stores[this.state.store][department]
                            )
                            .map((department) => (
                              <MenuItem value={department}>
                                {this.departments[department].name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                    )} */}
                  </Grid>
                  <Grid
                    style={{ marginBottom: 16 }}
                    container
                    justifyContent={"center"}
                    spacing={2}
                  >
                    {this.state.chartType === "volume" ? (
                      <Grid item xs={12} md={9}>
                        <Card>
                          {this.state.volumePer30Minutes && (
                            <VolumeChart
                              departments={Object.keys(this.departments)
                                .filter(
                                  (department) =>
                                    (department !== "ql" &&
                                      department !== "bshop") ||
                                    this.stores[this.state.store][department]
                                )
                                .map((department) => {
                                  return {
                                    name: this.departments[department].name,
                                    key: department,
                                  };
                                })}
                              data={this.state.volumePer30Minutes}
                              labels={Object.keys(
                                this.state.volumePer30Minutes
                              ).map((time) =>
                                moment("1997-01-01 " + time).format("hh:mmA")
                              )}
                              key={this.volumeChartCounter}
                            />
                          )}
                        </Card>
                      </Grid>
                    ) : this.state.chartType === "total" ? (
                      <>
                        {/* <Grid item xs={12} md={6}>
                          <Card>
                            <TotalChart
                              title={this.state.department + " chart"}
                              stacked={true}
                              key={this.departmentChartCounter}
                              labels={[
                                "Total : " +
                                  Object.values(
                                    this.state.chartData ?? [0]
                                  ).reduce((total, num) => total + num, 0),
                              ]}
                              data={this.state.chartData}
                            />
                          </Card>
                        </Grid> */}
                        <Grid item>
                          <Card>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <b>Department</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Total</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(this.state.storeTotalData ?? {})
                                  ?.filter(
                                    (department) =>
                                      (department !== "ql" &&
                                        department !== "bshop") ||
                                      this.stores[this.state.store][department]
                                  )
                                  .map((department) => {
                                    return (
                                      <TableRow
                                        style={{
                                          cursor: this.departments[department]
                                            .requiresSource
                                            ? "pointer"
                                            : "disabled",
                                        }}
                                        onClick={() =>
                                          this.handleChangeDepartment(
                                            department
                                          )
                                        }
                                      >
                                        <TableCell>
                                          {
                                            this.state.storeTotalData[
                                              department
                                            ].name
                                          }
                                        </TableCell>
                                        <TableCell>
                                          {
                                            this.state.storeTotalData[
                                              department
                                            ].count
                                          }
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </Card>
                        </Grid>
                        {this.state.sourcesData && (
                          <Grid item xs={12} md={6}>
                            <Card>
                              <TotalChart
                                title={this.state.department + " source chart"}
                                hasSources={true}
                                stacked={false}
                                key={this.departmentChartCounter}
                                labels={
                                  this.state.chartData
                                    ? Object.keys(this.state.chartData).filter(
                                        (item) => item !== "general"
                                      )
                                    : []
                                }
                                data={this.state.sourcesData ?? []}
                              />
                            </Card>
                          </Grid>
                        )}
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} md={6}>
                          <Card>
                            <TotalStoreChart
                              title={this.state.store + " chart"}
                              // key={this.storeTotalChartCounter}
                              departments={this.departments}
                              allUsers={!this.state.user}
                              labels={
                                this.state.user
                                  ? [this.state.user]
                                  : this.state.users
                              }
                              data={
                                this.state.user
                                  ? Object.keys(this.state.storeTotalData).map(
                                      (department) => {
                                        return {
                                          count:
                                            this.state.storeTotalData[
                                              department
                                            ].count,
                                          name: this.state.storeTotalData[
                                            department
                                          ].name,
                                        };
                                      }
                                    )
                                  : this.state.storeTotalUserBasedData
                              }
                            />
                          </Card>
                        </Grid>
                        <Grid item>
                          <Card>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <b>Department</b>
                                  </TableCell>
                                  <TableCell>
                                    <b>Total</b>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(this.state.storeTotalData)
                                  .filter(
                                    (department) =>
                                      (department !== "ql" &&
                                        department !== "bshop") ||
                                      this.stores[this.state.store][department]
                                  )
                                  .map((department) => {
                                    return (
                                      <TableRow>
                                        <TableCell>
                                          {
                                            this.state.storeTotalData[
                                              department
                                            ].name
                                          }
                                        </TableCell>
                                        <TableCell>
                                          {
                                            this.state.storeTotalData[
                                              department
                                            ].count
                                          }
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </Card>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </DialogContent>
              </Dialog>
            }
            <Dialog
              open={this.state.sourceSelection !== null}
              maxWidth={"xs"}
              fullWidth
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>CHOOSE SOURCE</h4>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.setState({ sourceSelection: null })}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <List>
                  {this.state.sources
                    .filter((source) => source.store === this.state?.store)
                    .map((source) => (
                      <ListItemButton
                        onClick={() =>
                          this.handleTickerCountUpdate({
                            type: this.state.sourceSelection.type,
                            kind: this.state.sourceSelection.kind,
                            source: source.source,
                          })
                        }
                      >
                        <ListItemText>{source.source}</ListItemText>
                        <ListItemIcon>
                          <KeyboardArrowRight />
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                </List>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.sourceDialog}
              maxWidth={"xs"}
              fullWidth
              onClose={() => this.setState({ sourceDialog: false })}
            >
              <AppBar sx={{ position: "relative" }}>
                <Toolbar
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>CONTRACT SOURCE</h4>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => this.setState({ sourceDialog: null })}
                    aria-label="close"
                  >
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <List>
                  {this.state.sources
                    .filter((source) => source.store === this.state?.store)
                    .map((source) => (
                      <ListItem>
                        <ListItemText>{source.source}</ListItemText>
                        <ListItemSecondaryAction>
                          {this.state.deletingSourceID === source.ID ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              color="error"
                              onClick={() =>
                                this.deleteSource(source.source, source.ID)
                              }
                            >
                              <Clear />
                            </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                </List>
                <Button
                  onClick={() => this.setState({ addSourceDialog: true })}
                  startIcon={<Add />}
                >
                  Add Source
                </Button>
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.addSourceDialog}
              onClose={() => this.setState({ addSourceDialog: false })}
            >
              <DialogContent>
                <Grid container justifyContent={"center"} spacing={8}>
                  <Grid item>
                    <TextField
                      required
                      label="Name"
                      name="newSourceName"
                      onBlur={this.handleChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {this.state.savingSource ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Button
                      color="error"
                      variant="contained"
                      startIcon={<Clear />}
                      onClick={() => this.setState({ addSourceDialog: false })}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.addSource}
                      startIcon={<Save />}
                    >
                      Save
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.refreshOnly}>
              <DialogContent>
                <Grid container justifyContent={"center"} spacing={8}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
    );
  }
}

export default HomePage;
