import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Store Total",
    },
  },
};

const colors = [
  "#6ba547",
  "#619ed6",
  "#e48f1b",
  "#e64345",
  "#f7d027",
  "indigo",
  "black",
  "blue",
  "green",
  "orange",
  "purple",
  "teal",
  "brown",
  "grey",
  "yellow",
  "pink",
];
export default function TotalChart(props) {
  console.log("total chart data");
  console.log(props.data);
  var data = {
    labels: props.allUsers ? Object.keys(props.data) : ["Total"],
    datasets: props.allUsers
      ? Object.keys(props.departments).map((department, i) => {
          return {
            label: department,
            data: Object.keys(props.data).map((user) => {
              return props.data[user][department];
            }),

            borderColor: colors[i],
            backgroundColor: colors[i],
          };
        })
      : props.data.map((department, i) => {
          return {
            label: department.name,
            data: [department.count],
            borderColor: colors[i],
            backgroundColor: colors[i],
          };
        }),
  };
  return <Bar options={options} data={data} />;
}
