import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
const colors = [
  "#6ba547",
  "#619ed6",
  "#e48f1b",
  "#e64345",
  "#f7d027",
  "indigo",
  "black",
  "blue",
  "green",
  "orange",
  "purple",
  "teal",
  "brown",
  "grey",
  "yellow",
  "pink",
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default class TotalChart extends React.Component {
  state = {
    loading: false,
    data: {
      labels: this.labels,
      datasets: [
        {
          label: "Dataset 1",
          data: [],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Dataset 2",
          data: [],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    },
  };

  labels = [];

  options = {
    scales: {
      x: {
        stacked: this.props.stacked,
      },
      y: {
        stacked: this.props.stacked,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    indexAxis: window.innerWidth < 960 ? "y" : "x",
    barThickness: 16,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: this.props.title.toUpperCase(),
      },
    },
  };

  componentDidMount() {
    console.log(this.props);
    this.labels = this.props.labels;

    this.setState({
      data: {
        labels: this.labels,
        datasets: this.props.hasSources
          ? Object.keys(this.props.data).map((source, index) => {
              console.log("this.props.data[source]");
              console.log(this.props.data[source]);
              return {
                label: source.toUpperCase(),
                borderColor: "#fff",
                backgroundColor: colors[index],
                data: this.labels.map((item) => {
                  console.log("item");
                  console.log(item);
                  return this.props.data[source][item];
                }),
              };
            })
          : Object.keys(this.props.data).map((item, index) => {
              console.log(this.props.data[item]);
              return {
                label: item.toUpperCase(),
                data: [this.props.data[item]],
                borderColor: "#fff",
                backgroundColor: colors[index],
              };
            }),
        // datasets: [
        //   {
        //     label: "Total",
        //     data: Object.keys(this.props.data).map(
        //       (item) => this.props.data[item]
        //     ),
        //     borderColor: "#fff",
        //     backgroundColor: colors[0],
        //   },
        // ],
      },
    });
  }
  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <Bar
          height={window.innerWidth < 960 ? 700 : null}
          options={this.options}
          data={this.state.data}
        />
      </div>
    );
  }
}
