import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Volume Per Hour",
    },
  },
};

const labels = [
  "8AM",
  "8:30",
  "9AM",
  "9:30",
  "10AM",
  "",
  "11AM",
  "",
  "12PM",
  "",
  "1PM",
  "2PM",
  "3PM",
  "4PM",
  "5PM",
  "6PM",
];

const colors = [
  "#6ba547",
  "#619ed6",
  "#e48f1b",
  "#e64345",
  "#f7d027",
  "indigo",
  "black",
  "blue",
  "green",
  "orange",
  "purple",
  "teal",
  "brown",
  "grey",
  "yellow",
  "pink",
];
const timeLabels = [
  "8AM",
  "8:30AM",
  "9AM",
  "9:30AM",
  "10AM",
  "10:30AM",
  "11AM",
  "11:30AM",
  "12PM",
  "12:30PM",
  "1PM",
  "1:30PM",
  "2PM",
  "2:30PM",
  "3PM",
  "3:30PM",
  "4PM",
  "4:30PM",
  "5PM",
  "5:30PM",
  "6PM",
  "6:30PM",
  "7PM",
];

export default function VolumeChart(props) {
  console.log("props.data");
  console.log(props.data);
  var data = {
    // labels: timeLabels,
    // labels: props.labels.map((time) => time),
    labels: props.labels,
    datasets: props.departments.map((department, i) => {
      return {
        label: department.name,
        data: Object.keys(props.data).map(
          (time) => props.data[time][department.key] ?? 0
        ),
        borderColor: colors[i],
        backgroundColor: colors[i],
      };
    }),
  };
  return <Line options={options} data={data} />;
}
