import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Grid, CircularProgress } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import HomePage from "./routes/home";
import LoginPage from "./routes/login";
import { Redirect } from "react-router-dom";

class App extends React.Component {
  state = { loading: true };

  setSession = () => {
    var localJWT = localStorage.getItem("jwt");
    var sessionJWT = sessionStorage.getItem("jwt");
    if (localJWT && !sessionJWT) {
      sessionStorage.setItem("jwt", localJWT);
      sessionStorage.setItem("user", localStorage.getItem("user"));
    }
    this.setState({ loading: false });
  };

  isAuth = () => {
    return sessionStorage.getItem("jwt") != null;
  };

  componentDidMount() {
    this.setSession();
  }
  render() {
    if (this.state.loading)
      return (
        <Grid container justifyContent={"center"}>
          <CircularProgress />
        </Grid>
      );
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="App">
          <Router>
            <Switch>
              <Route
                exact
                path="/login"
                render={(
                  props //using on sucess to just re-render the dom so that isAuth func can rerender and conditional value would change to stop redirect to login page.
                ) => (
                  <LoginPage {...props} onSuccess={() => this.setSession()} />
                )}
              />
              {!this.isAuth() && <Redirect to="/login" />}
              <Route exact path="/" component={HomePage} />
            </Switch>
          </Router>
        </div>
      </LocalizationProvider>
    );
  }
}

export default App;
